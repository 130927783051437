<template>
  <v-btn
    class="sso-button"
    color="white"
    fab
    depressed
    :loading="loading"
    :disabled="loading"
    @click="$emit('start-sso-flow', ssoUrl || ssoProvider)">
    <v-icon :size="size">
      {{ `$vuetify.icons.${ ssoProvider }` }}
    </v-icon>
  </v-btn>
</template>

<script>

export default {
  name: 'CySSOButton',
  props: {
    ssoProvider: {
      type: String,
      default: null,
    },
    ssoUrl: {
      type: String,
      default: null,
    },
    size: {
      type: Number,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.sso-button {
  box-shadow:
    0 1px 5px rgb(37 52 73 / 0.2),  /* rgba(37, 52, 73, 0.2) */
    0 1px 1px rgba(37 52 73 / 0.14), /* rgba(37, 52, 73, 0.14) */
    0 1px 3px rgba(37 52 73 / 0.12); /* rgba(37, 52, 73, 0.12) */
}
</style>
